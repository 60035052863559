exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bewertungen-js": () => import("./../../../src/pages/bewertungen.js" /* webpackChunkName: "component---src-pages-bewertungen-js" */),
  "component---src-pages-buch-js": () => import("./../../../src/pages/buch.js" /* webpackChunkName: "component---src-pages-buch-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-praxis-js": () => import("./../../../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */)
}

